<template>
  <div class="page__wrapper">
    <ag-grid
      ref="reportReviewGrid"
      pagination
      style="width: 100%; height: 100%"
      :column-defs="columnDefs"
      :row-data.sync="tableData"
      :pagination-page-size="tablePageInfo.pageSize"
      :framework-components="frameworkComponents"
      :grid-options="gridOptions"
    >
      <simple-form
        v-model="searchModel"
        :form-field="searchFormField"
      >
        <template slot="tableOperation">
          <el-button @click="handleDataSearch">
            {{ $t("operation.search") }}
          </el-button>
        </template>
      </simple-form>
    </ag-grid>
    <detail-dialog
      :show-flag.sync="isShowDetailDialog"
      :detail-data="rowData"
      :view-flag="isViewFlag"
    />
    <!-- 详情页 New -->
    <new-detail-dialog
      :show-flag.sync="isShowNewDetailDialog"
      :detail-data="rowData"
      :view-flag="isViewFlag"
    />

    <!-- 详情页 2022 -->
    <detail-2022-dialog
      :show-flag.sync="isShowDetail2022Dialog"
      :detail-data="rowData"
      :view-flag="isViewFlag"
    />

    <!-- 详情页 202206 -->
    <detail-202206-dialog
      :show-flag.sync="isShowDetail202206Dialog"
      :detail-data="rowData"
      :view-flag="isViewFlag"
    />

    <!-- 详情页 202308 -->
    <detail-202308-dialog
      :show-flag.sync="isShowDetail202308Dialog"
      :detail-data="rowData"
      :view-flag="isViewFlag"
    />

    <anomaly
      :show-flag.sync="isShowAnomalyDialog"
      :view-flag="isViewFlag"
      :is-third-party="true"
      :detail-data="rowData"
    />
    <confirm
      :show-flag.sync="isShowConfirmDialog"
      :detail-data="rowData"
    />
    <appeal
      :show-flag.sync="isShowAppealDialog"
      :detail-data="rowData"
      :view-flag="isViewFlag"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import detailDialog from '../../dealer/finance/detail'
import newDetailDialog from '../../dealer/finance/detailNew'
import detail2022Dialog from '../../dealer/finance/detail2022'
import detail202206Dialog from '../../dealer/finance/detail202206'
import detail202308Dialog from '../../dealer/finance/detail202308'
import confirm from './confirm'
import appeal from './appeal'
import anomaly from '../../dealer/anomaly/anomaly'
import pageMixins from '@/components/base/simple-table/mixin'

const BASEURL = {
  list: '/jlr/tt/selfExaminationData/list',
  office: '/system/office/list',
  export: '/jlr/tt/selfExaminationData/export'
}

/** 自定义操作列 */
const Operation = Vue.extend({
  inject: [
    'handleDataSearch',
    'handleViewClick',
    'handleCheckClick',
    'handleConfirmClick',
    'handleAppealClick'
  ],
  template: `<div style="height:100%;text-align:center;">
      <el-tooltip effect="light" :content="$t('operation.preview')">
        <el-button v-permission="'dealer_reviewConfirm:view'" type="text" style="font-size:14px;" icon="el-icon-search"
          @click="handleViewClick(params.data)" />
      </el-tooltip>
      <el-tooltip effect="light" :content="$t('thirdParty_reportReview.anomalyReview')">
        <el-button type="text" style="font-size:14px;" icon="el-icon-s-check" v-permission="'dealer_reviewConfirm:anomaly'"
          @click="handleCheckClick(params.data)" :disabled="!params.data.id" />
      </el-tooltip>
      <el-tooltip effect="light" :content="$t('dealer_reviewConfirm.confirm')">
        <el-button v-permission="'dealer_reviewConfirm:confirm'" type="text" style="font-size:14px;" icon="el-icon-success" @click="handleConfirmClick(params.data)"
          :disabled="params.data.reviewConfirmStatus==='tt_review_confirm_status_1' || params.data.reviewConfirmStatus==='tt_review_confirm_status_2'"/>
      </el-tooltip>
      <el-tooltip effect="light" :content="$t('dealer_reviewConfirm.appeal')">
        <el-button type="text" v-permission="'dealer_reviewConfirm:appeal'" style="font-size:14px;" icon="el-icon-warning"
          @click="handleAppealClick(params.data)" :disabled="params.data.reviewConfirmStatus==='tt_review_confirm_status_1'" />
      </el-tooltip>
    </div>`
})

export default {
  name: 'ReviewConfirm',
  components: {
    detailDialog,
    confirm,
    anomaly,
    appeal,
    newDetailDialog,
    detail2022Dialog,
    detail202206Dialog,
    detail202308Dialog
  },
  mixins: [ pageMixins ],
  data () {
    return {
      rowData: {},
      tableData: [],
      dealerList: [],
      gridOptions: {},
      searchModel: {
        period: this.$moment().subtract(1, 'months').format('YYYYMM')
      },
      isViewFlag: false,
      frameworkComponents: {},
      isShowDetailDialog: false,
      isShowAppealDialog: false,
      isShowConfirmDialog: false,
      isShowAnomalyDialog: false,
      isShowNewDetailDialog: false,
      isShowDetail2022Dialog: false,
      isShowDetail202206Dialog: false,
      isShowDetail202308Dialog: false
    }
  },
  provide () {
    return {
      handleDataSearch: this.handleDataSearch,
      handleViewClick: this.handleViewClick,
      handleCheckClick: this.handleCheckClick,
      handleConfirmClick: this.handleConfirmClick,
      handleAppealClick: this.handleAppealClick
    }
  },
  computed: {
    searchFormField () {
      return [
        {
          prop: 'period',
          type: 'Datepicker',
          col: { xs: 4, sm: 4, md: 4 },
          label: this.$t('thirdParty_reportExport.month'),
          component: { type: 'month', valueFormat: 'yyyyMM', clearable: false }
        },
        {
          slotName: 'tableOperation',
          col: { xs: 20, sm: 20, md: 20 },
          style: { textAlign: 'right' },
          labelWidth: '0'
        }
      ]
    },
    columnDefs () {
      return [
        {
          headerName: this.$t('dealer_finance.dealerName'),
          field: 'dealer.name',
          pinned: 'left',
          minWidth: 200
        },
        {
          headerName: this.$t('dealer_finance.dealerCode'),
          field: 'dealer.code',
          pinned: 'left',
          minWidth: 200
        },
        {
          headerName: this.$t('dealer_finance.area'),
          field: 'region',
          valueFormatter: (params) =>
            this.$getDictLabel({
              type: 'sys_office_region',
              value: params.value
            }),
          minWidth: 160
        },
        {
          headerName: this.$t('dealer_finance.group'),
          field: 'dealerParent',
          valueFormatter: (params) =>
            params.data.dealerParent ? params.data.dealerParent.name || '' : '',
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return valueA && valueB
              ? valueA.name.localeCompare(valueB.name)
              : -1
          },
          minWidth: 200
        },
        {
          headerName: this.$t('dealer_finance.version'),
          field: 'version',
          minWidth: 160
        },
        {
          headerName: this.$t('dealer_finance.cycle'),
          field: 'period',
          valueFormatter: (params) =>
            params.value
              ? this.$moment(params.value, 'YYYYMM').format('YYYY年MM月')
              : '',
          minWidth: 160
        },
        {
          headerName: this.$t('dealer_finance.reporter'),
          field: 'compiler',
          minWidth: 120
        },
        {
          headerName: this.$t('dealer_finance.reporterPosition'),
          field: 'compilerPosition',
          minWidth: 120
        },
        // {
        //   headerName: this.$t('dealer_finance.reporterPhone'),
        //   field: 'compilerPhone',
        //   minWidth: 120
        // },
        {
          headerName: this.$t('dealer_finance.reporterEmail'),
          field: 'compilerMail',
          minWidth: 200
        },
        {
          headerName: this.$t('dealer_finance.confirmDate'),
          field: 'reviewConfirmDate',
          minWidth: 200
        },
        {
          headerName: this.$t('dealer_finance.status'),
          field: 'bizStatus',
          valueFormatter: (params) =>
            this.$getDictLabel({
              type: 'tt_self_examination_data_head_biz_status',
              value: params.value
            }),
          minWidth: 160
        },
        {
          headerName: this.$t('dealer_finance.reviewConfirmStatus'),
          field: 'reviewConfirmStatus',
          valueFormatter: (params) =>
            this.$getDictLabel({
              type: 'tt_review_confirm_status',
              value: params.value
            }),
          minWidth: 120
        },
        {
          headerName: this.$t('dealer_finance.reviewConfirmUser'),
          field: 'reviewConfirmUser',
          minWidth: 120
        },
        {
          headerName: this.$t('field.operation'),
          width: 140,
          suppressSizeToFit: true,
          pinned: 'right',
          cellRenderer: 'Operation'
        }
      ]
    }
  },
  beforeMount () {
    this.frameworkComponents = { Operation: Operation }
  },
  mounted () {
    this.getDealerList()
    this.handleDataSearch()
  },
  methods: {
    handleDataSearch () {
      const params = Object.assign(
        {},
        this.$utils.parseQueryCondition(this.searchModel, this.searchFormField)
      )
      const loadingFlag = this.$loading({
        fullscreen: false,
        target: this.$el,
        text: this.$t('tip.systemLoading')
      })
      params.filter.push({ left: 'isSubmit', operate: '=', right: 'Y' })
      params.filter.push({ left: 'bizStatus', operate: '=', right: 'V3' })
      params.filter.push({
        left: 'reviewRelease',
        operate: '=',
        right: 'tt_review_release_1'
      })
      this.$axios
        .post(BASEURL.list, params)
        .then((resp) => {
          const respData = resp.data
          this.tableData = respData.list
        })
        .finally(() => {
          loadingFlag.close()
        })
    },
    handleViewClick (row) {
      this.rowData = this.$_.cloneDeep(row)
      this.isViewFlag = true
      if (
        this.$moment(row.period, 'YYYYMM') < this.$moment('202101', 'YYYYMM')
      ) {
        this.isShowDetailDialog = true
      } else if (
        this.$moment(row.period, 'YYYYMM') < this.$moment('202201', 'YYYYMM')
      ) {
        this.isShowNewDetailDialog = true
      } else if (
        this.$moment(row.period, 'YYYYMM') < this.$moment('202206', 'YYYYMM')
      ) {
        this.isShowDetail2022Dialog = true
      } else if (
        this.$moment(row.period, 'YYYYMM') < this.$moment('202308', 'YYYYMM')
      ) {
        this.isShowDetail202206Dialog = true
      } else {
        this.isShowDetail202308Dialog = true
      }
    },
    handleCheckClick (rowData) {
      this.isViewFlag = rowData.isSubmit === 'Y'
      this.rowData = this.$_.cloneDeep(rowData)
      this.isShowAnomalyDialog = true
    },
    getDealerList (name) {
      this.dealerLoading = true
      const params = {
        filter: [
          { left: 'category', operate: '=', right: 'sys_office_category_3' }
        ]
      }
      this.$axios
        .post(BASEURL.office, params)
        .then((resp) => {
          const respData = resp.data
          this.dealerList = respData.list.map((item) => {
            return { key: item.id, value: item, label: item.name }
          })
        })
        .finally(() => {
          this.dealerLoading = false
        })
    },
    handleConfirmClick (rowData) {
      this.rowData = this.$_.cloneDeep(rowData)
      this.isShowConfirmDialog = true
    },
    handleAppealClick (rowData) {
      this.rowData = this.$_.cloneDeep(rowData)
      this.isViewFlag =
        rowData.reviewConfirmStatus === 'tt_review_confirm_status_2'
      this.isShowAppealDialog = true
    }
  }
}
</script>
<style lang="less" scoped>
.page__wrapper {
  width: 100%;
  height: 100%;
}
</style>
