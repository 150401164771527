<template>
  <el-dialog
    :visible.sync="currentShowFlag"
    append-to-body
    fullscreen
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @open="handleDialogOpen"
    @closed="handleDialogClose"
  >
    <div
      slot="title"
      class="detail-dialog__header"
    >
      {{ isThirdParty ? $t('thirdParty_reportReview.anomalyReview') : $t('dealer_anomaly.anomalyDealerExplain') }}
    </div>
    <el-tabs
      v-model="activeName"
      @tab-click="handleTabClick"
    >
      <el-tab-pane
        :label="$t('dealer_anomaly.fluctuationCheck')"
        name="fluctuationCheck"
      >
        <fluctuation-check
          ref="fluctuationCheck"
          :detail-data="detailData"
          :view-flag="viewFlag"
          :is-third-party="isThirdParty"
        />
      </el-tab-pane>
      <el-tab-pane
        :label="$t('dealer_anomaly.thresholdCheck')"
        name="thresholdCheck"
      >
        <threshold-check
          ref="thresholdCheck"
          :view-flag="viewFlag"
          :detail-data="detailData"
          :is-third-party="isThirdParty"
        />
      </el-tab-pane>
      <el-tab-pane
        :label="$t('dealer_anomaly.logicalCheck')"
        name="logicalCheck"
      >
        <logical-check
          ref="logicalCheck"
          :view-flag="viewFlag"
          :detail-data="detailData"
          :is-third-party="isThirdParty"
        />
      </el-tab-pane>
    </el-tabs>
    <div slot="footer">
      <!-- <el-button
        v-if="isThirdParty"
        type="primary"
        @click="handleExportClick"
      >
        {{ $t('operation.export') }}
      </el-button> -->
      <el-button
        @click="handleDialogClose"
      >
        {{ viewFlag ? $t('operation.close') : $t('operation.cancel') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import fluctuationCheck from '../../dealer/anomaly/fluctuationCheck'
import thresholdCheck from '../../dealer/anomaly/thresholdCheck'
import logicalCheck from '../../dealer/anomaly/logicalCheck'

export default {
  name: 'Anomaly',
  components: { fluctuationCheck, thresholdCheck, logicalCheck },
  props: {
    showFlag: { type: Boolean, default: false },
    viewFlag: { type: Boolean },
    detailData: { type: Object },
    isThirdParty: { type: Boolean, default: false }
  },
  data () {
    return {
      activeName: 'fluctuationCheck'
    }
  },
  inject: ['handleDataSearch'],
  computed: {
    currentShowFlag: {
      get () {
        return this.showFlag
      },
      set (val) {
        this.$emit('update:showFlag', val)
      }
    }
  },
  methods: {
    handleDialogClose () {
      this.currentShowFlag = false
    },

    handleDialogOpen () {
      this.activeName = 'fluctuationCheck'
      this.$nextTick(() => {
        this.$refs.fluctuationCheck.handleDataSearch()
      })
    },

    handleTabClick (tab) {
      this.$refs[tab.name].handleDataSearch()
    }
  }
}
</script>
